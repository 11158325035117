/*Mixins*/
/*-------------------------------------------------------------------------------------------------------------------------------*/
/*This is helpers */
/*-------------------------------------------------------------------------------------------------------------------------------*/

/* ===[ Paddings ]===
   ==================================================
   ================================================== */
.no-padding{ padding:0px; }

.padding-0{ padding:0px; }
.padding-10{ padding:10px; }
.padding-15{ padding:15px; }
.padding-20{ padding:20px; }
.padding-30{ padding:30px; }
.padding-40{ padding:40px; }
.padding-50{ padding:50px; }

.padding-top-0{ padding-top:0px; }
.padding-top-5{ padding-top:5px; }
.padding-top-10{ padding-top:10px; }
.padding-top-20{ padding-top:20px; }
.padding-top-30{ padding-top:30px; }
.padding-top-40{ padding-top:40px; }
.padding-top-50{ padding-top:50px; }
.padding-top-60{ padding-top:60px; }
.padding-top-70{ padding-top:70px; }
.padding-top-80{ padding-top:80px; }
.padding-top-90{ padding-top:90px; }
.padding-top-100{ padding-top:100px; }
.padding-top-110{ padding-top:110px; }
.padding-top-120{ padding-top:120px; }
.padding-top-130{ padding-top:130px; }
.padding-top-140{ padding-top:140px; }
.padding-top-150{ padding-top:150px; }

.padding-bottom-5{ padding-bottom:5px; }
.padding-bottom-10{ padding-bottom:10px; }
.padding-bottom-20{ padding-bottom:20px; }
.padding-bottom-30{ padding-bottom:30px; }
.padding-bottom-40{ padding-bottom:40px; }
.padding-bottom-50{ padding-bottom:50px; }
.padding-bottom-60{ padding-bottom:60px; }
.padding-bottom-70{ padding-bottom:70px; }
.padding-bottom-80{ padding-bottom:80px; }
.padding-bottom-90{ padding-bottom:90px; }
.padding-bottom-100{ padding-bottom:100px; }
.padding-bottom-110{ padding-bottom:110px; }
.padding-bottom-120{ padding-bottom:120px; }
.padding-bottom-130{ padding-bottom:130px; }
.padding-bottom-140{ padding-bottom:140px; }
.padding-bottom-150{ padding-bottom:150px; }


/* ===[ Margins ]===
   ==================================================
   ================================================== */
.no-margin{ margin:0px; }

.margin-0{ margin:0px; }
.margin-5{ margin:5px; }
.margin-10{ margin:10px; }
.margin-15{ margin:15px; }
.margin-20{ margin:20px; }
.margin-30{ margin:30px; }
.margin-40{ margin:40px; }
.margin-50{ margin:50px; }

.margin-bottom-10{ margin-bottom:10px; }
.margin-bottom-20{ margin-bottom:20px; }
.margin-bottom-30{ margin-bottom:30px; }
.margin-bottom-40{ margin-bottom:30px; }
.margin-bottom-40{ margin-bottom:40px; }
.margin-bottom-50{ margin-bottom:50px; }
.margin-bottom-60{ margin-bottom:60px; }
.margin-bottom-70{ margin-bottom:70px; }
.margin-bottom-80{ margin-bottom:80px; }
.margin-bottom-90{ margin-bottom:90px; }
.margin-bottom-100{ margin-bottom:100px; }
.margin-bottom-110{ margin-bottom:110px; }
.margin-bottom-120{ margin-bottom:120px; }
.margin-bottom-130{ margin-bottom:130px; }
.margin-bottom-140{ margin-bottom:140px; }
.margin-bottom-150{ margin-bottom:150px; }
.margin-bottom-200{ margin-bottom:200px; }
.margin-bottom-250{ margin-bottom:250px; }

.margin-right-10{ margin-right:10px; }
.margin-right-20{ margin-right:20px; }
.margin-right-30{ margin-right:30px; }
.margin-right-40{ margin-right:30px; }
.margin-right-40{ margin-right:40px; }
.margin-right-50{ margin-right:50px; }

.margin-top-0{ margin-top:0px; }
.margin-top-10{ margin-top:10px; }
.margin-top-20{ margin-top:20px; }
.margin-top-30{ margin-top:30px; }
.margin-top-40{ margin-top:40px; }
.margin-top-50{ margin-top:50px; }
.margin-top-60{ margin-top:60px; }
.margin-top-70{ margin-top:70px; }
.margin-top-80{ margin-top:80px; }
.margin-top-90{ margin-top:90px; }
.margin-top-100{ margin-top:100px; }
.margin-top-110{ margin-top:110px; }
.margin-top-120{ margin-top:120px; }
.margin-top-130{ margin-top:130px; }
.margin-top-140{ margin-top:140px; }
.margin-top-150{ margin-top:150px; }
.margin-top-200{ margin-top:200px; }
.margin-top-250{ margin-top:250px; }


/* ===[ Positions ]===
   ==================================================
   ================================================== */
.position-relative{ position:relative; }
.position-absolute{ position:absolute; }


/* ===[ Display ]===
   ==================================================
   ================================================== */
.inline { display:inline-block; vertical-align: top; float:left; }
ul.inline li { display:inline-block; vertical-align: top; float:left; }
.display-inline-block{ display:inline-block; }


/* ===[ Borders ]===
   ==================================================
   ================================================== */
.bordered{ border: solid 1px #f1f1f1; }


/* ===[ Rounds ]===
   ==================================================
   ================================================== */
.no-rounded{
    border-radius:0px;
    -webkit-border-radius:0px;
}

.rounded{
    border-radius:7px;
    -webkit-border-radius:7px;
}

.rounded-circle{
    border-radius:100%;
    -webkit-border-radius:100%;
}


/* ===[ Fonts ]===
   ==================================================
   ================================================== */
.uppercase{ text-transform:uppercase; }
.italic{ font-style:italic }
.bold{ font-weight:700 }

