form .form-group{
  position:relative;
}

form label.error{
  background:@brand-danger;
  color:#fff;
  padding:2px;
  position:absolute;
  top:5px;
  right:4px;
  z-index:100;
}

form .date.datetimepicker .error
.form-captcha + .error{
  margin-right:40px;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea,
.form-control{
  background:#f8f8f8;
  border-radius: 0;
}

label{
  display:none;
}