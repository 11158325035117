@import '../../packages/bootstrap/less/bootstrap.less';
@import '../../packages/fontawesome/less/font-awesome.less';

@import '../variables.less';
@import '../mixins.less';
@import '../tinymcebootstrap.less';
@import '../form.less';

/* ===[ table of content ]===
1.   Initialize
2.   Header
3.   Main
4.   Footer
   ================================================== */

/* ===[ Initialize ]===
   ==================================================
   ================================================== */

#mapCanvas {
  position: relative;
  height: 300px;
  overflow: hidden;
}

#mapCanvas iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/* ===[ Header ]===
   ==================================================
   ================================================== */


/* ===[ Main ]===
   ==================================================
   ================================================== */


/* ===[ Footer ]===
   ==================================================
   ================================================== */

@import '../custom.less';